<script lang="ts" setup>
    import { required } from "@/composables/dutchVuelidate";
    import { OrderDetails } from "@/models/OrderDetails";
    import EditTextField from "@/components/orderEdit/EditTextField.vue";
    import { OrderPermissions } from "@/models/OrderPermissions";
    import PaymentStatusChip from "../common/chips/PaymentStatusChip.vue";
    import PaymentMethodChip from "../common/chips/PaymentMethodChip.vue";
    import DeliveryStatusChip from "@/components/common/chips/DeliveryStatusChip.vue";
    import { onMounted, ref } from "vue";
    import userService, { User } from "@/services/UserService";

    const order = defineModel<OrderDetails>({ required: true });

    const { orderPermissions } = defineProps<{
        orderPermissions: OrderPermissions;
    }>();

    const users = ref<User[]>([]);

    onMounted(async () => {
        if (orderPermissions.categories.responsibility) {
            const response = await userService.getUsers();
            users.value = response.data;
        }
    });
</script>

<template>
    <v-card class="mb-5" title="Bestel informatie">
        <v-card-text>
            <v-table theme="light">
                <tbody>
                    <tr>
                        <td>Bestelnummer</td>
                        <td>
                            <EditTextField
                                v-model="order.orderId"
                                :can-edit="false"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Naam klant</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.name"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Opmerking (klant)</td>
                        <td>
                            <EditTextField
                                v-model="order.remarks.customerRemark"
                                :can-edit="orderPermissions.categories.customer"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Opmerking (systeem)</td>
                        <td>
                            <EditTextField
                                v-model="order.remarks.systemRemark"
                                :can-edit="
                                    orderPermissions.categories.systemRemark
                                "
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Telefoonnummer</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.telephone"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Mailadres</td>
                        <td>
                            <EditTextField
                                v-model="order.customer.email"
                                :can-edit="orderPermissions.categories.customer"
                                :rules="{
                                    required,
                                }"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalmethode</td>
                        <td>
                            <PaymentMethodChip
                                :method="order.paymentMethod"
                                text="label"
                            ></PaymentMethodChip>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalstatus</td>
                        <td>
                            <payment-status-chip
                                :status="order.paymentStatus"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Bezorgstatus</td>
                        <td>
                            <delivery-status-chip
                                :deliver="order.deliver"
                                :delivered="order.state.delivered"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Verantwoordelijke</td>
                        <td>
                            <template
                                v-if="
                                    orderPermissions.categories.responsibility
                                "
                            >
                                <v-select
                                    v-model="order.responsibleUser"
                                    :hide-details="true"
                                    :items="users"
                                    placeholder="Geen verantwoordelijke"
                                    item-title="fullName"
                                    return-object
                                    density="compact"
                                    variant="outlined"
                                />
                            </template>
                            <template v-else>
                                <td v-if="order.responsibleUser">
                                    {{ order.responsibleUser?.fullName }}
                                </td>
                                <td v-else>Geen verantwoordelijke</td>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>
