<script lang="ts" setup>
    import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
    import "leaflet/dist/leaflet.css";
    import { computed, ref } from "vue";
    import { DeliveryFullfilment, OrderDetails } from "@/models/OrderDetails";

    const map = ref();

    const { orders = [] } = defineProps<{
        orders: OrderDetails[];
    }>();

    const center = computed(() => {
        const lat =
            orders.reduce(
                (acc, order) =>
                    acc + (order.fulfillment as DeliveryFullfilment).latitude,
                0,
            ) / orders.length;
        const lon =
            orders.reduce(
                (acc, order) =>
                    acc + (order.fulfillment as DeliveryFullfilment).longitude,
                0,
            ) / orders.length;
        return [lat, lon];
    });
</script>

<template>
    <div style="height: 40vh; width: 40vw">
        <l-map
            :ref="map"
            v-model:center="center"
            :zoom="13"
            :use-global-leaflet="false"
        >
            <l-tile-layer
                layer-type="base"
                name="OpenStreetMap"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <template v-for="order in orders" :key="order.id">
                <l-marker
                    :lat-lng="[
                        (order.fulfillment as DeliveryFullfilment).latitude,
                        (order.fulfillment as DeliveryFullfilment).longitude,
                    ]"
                />
            </template>
        </l-map>
    </div>
</template>

<style scoped></style>
