<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import { OrderContext, OrderPermissions } from "@/models/OrderPermissions";
    import orderService from "@/services/OrderService";
    import { computed, ref } from "vue";
    import { useRoute } from "vue-router";
    import { useDisplay } from "vuetify";
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import * as Sentry from "@sentry/browser";

    import EditOrderInformationCard from "@/components/orderEdit/EditOrderInformationCard.vue";
    import FulfillmentInformationCard from "@/components/orderDetail/FulfillmentInformationCard.vue";
    import EditProductsTable from "@/components/orderEdit/EditProductsTable.vue";
    import { cleanObject, getPatchObject } from "@/composables/requestHelpers";
    import router from "@/router";
    import TimeslotModal from "@/components/orderEdit/modals/TimeslotModal.vue";
    import { useNotificationStore } from "@/store/notification";

    const loading = ref<boolean>(true);
    const notFound = ref<boolean>(false);
    const route = useRoute();

    const orderCopy = ref<OrderDetails>();
    const order = ref<OrderDetails>();

    const orderPermissions = ref<OrderPermissions>();
    const orderEditContext = ref<OrderContext>();

    const { smAndDown } = useDisplay();

    const pageTitle = computed(() => {
        if (smAndDown.value) {
            return "";
        }
        if (loading.value) {
            return "Bestelling laden...";
        }
        if (notFound.value) {
            return "Onbekende bestelling";
        } else {
            return "Bestelling " + order.value!.orderId;
        }
    });

    const detailUrl = computed(() => `/orders/${route.params.id}`);

    Promise.all([
        orderService.getOrderById(parseInt(route.params.id as string)),
        orderService.getOrderPermissionsById(
            parseInt(route.params.id as string),
        ),
    ])
        .then(([orderRes, permissionsRes]) => {
            if (orderRes.data.responsibleUser !== undefined) {
                //FIXME: This is ugly as hell, I know, but needed due to inconsistent API. No shame.
                orderRes.data.responsibleUser.fullName =
                    orderRes.data.responsibleUser.firstName +
                    (orderRes.data.responsibleUser.infix
                        ? " " + orderRes.data.responsibleUser.infix
                        : " ") +
                    orderRes.data.responsibleUser.lastName;
            }
            //FIXME: use Klona for this
            // Deep copy mess
            order.value = JSON.parse(JSON.stringify(orderRes.data));
            orderCopy.value = JSON.parse(JSON.stringify(orderRes.data));

            if (order.value && orderCopy.value) {
                order.value.timeslot.day = new Date(
                    orderRes.data.timeslot.day as unknown as string,
                );

                orderCopy.value.timeslot.day = new Date(
                    orderRes.data.timeslot.day as unknown as string,
                );
            }

            orderPermissions.value = {
                categories: permissionsRes.data.categories,
                state: permissionsRes.data.state,
            };

            // Change to json date
            orderEditContext.value = permissionsRes.data.data;
            if (orderEditContext.value) {
                for (const day of orderEditContext.value.timeslots) {
                    day.date = new Date(day.date);
                }
            }
        })
        .catch(() => {
            notFound.value = true;
        })
        .finally(() => {
            loading.value = false;
        });

    const allowApplying = computed(() => {
        return true;
    });

    const timeslotDialog = ref<boolean>(false);
    const processing = ref<boolean>(false);

    const notificationStore = useNotificationStore();

    const onApply = async () => {
        processing.value = true;
        // Get all changes by running a diff between order and orderCopy
        const diff = getPatchObject(
            order.value,
            orderCopy.value,
        ) as Partial<OrderDetails>;

        const orderPatch = cleanObject({
            data: {
                name: diff.customer?.name,
                toggleableFields: {
                    email: diff.customer?.email,
                    telephone: diff.customer?.telephone,
                    donation: diff.priceFactors?.donation,
                    discount: diff.priceFactors?.discount,
                    customer_remark: diff.remarks?.customerRemark,
                    system_remark: diff.remarks?.systemRemark,
                },
            },
            responsibleUser: diff.responsibleUser?.id,
        });

        try {
            await orderService.updateOrderById(order.value!.id, orderPatch);
            await router.push({
                name: "orderDetail",
                params: { id: order.value!.id },
            });
        } catch (e) {
            Sentry.captureException(e);
            notificationStore.addNotification(
                "Er is iets fout gegaan bij het opslaan van de aanpassingen.",
                "error",
            );
        } finally {
            processing.value = false;
        }
    };
</script>
<template>
    <v-container fluid>
        <globy-app-bar :detail="detailUrl" :title="pageTitle">
            <template #actions>
                <v-spacer />
                <v-btn
                    :disabled="!orderPermissions?.categories.fulfillment"
                    class="mr-2"
                    text="Tijdvak aanpassen"
                    variant="tonal"
                    @click="timeslotDialog = true"
                />
                <v-btn
                    v-if="allowApplying"
                    prepend-icon="mdi-pen"
                    text="Toepassen"
                    variant="tonal"
                    :loading="processing"
                    @click="onApply"
                />
            </template>
        </globy-app-bar>
        <template v-if="!loading">
            <v-row v-if="notFound">
                <v-col>
                    <v-alert text="Bestelling niet gevonden" type="error" />
                </v-col>
            </v-row>
            <template v-else-if="order">
                <timeslot-modal
                    v-model="timeslotDialog"
                    :context="orderEditContext!"
                    :order="order"
                    @success="
                        router.push({
                            name: 'orderDetail',
                            params: { id: order!.id },
                        })
                    "
                />
                <v-row>
                    <v-col>
                        <EditOrderInformationCard
                            v-model="order"
                            :order-permissions="orderPermissions!"
                        />
                        <FulfillmentInformationCard :order="order" />
                    </v-col>
                    <v-col>
                        <edit-products-table
                            v-model="order"
                            :order-permissions="orderPermissions!"
                            class="mb-5"
                        />
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>
</template>
