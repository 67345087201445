import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    RouterOptions,
} from "vue-router";

// Define route components
import PageLayout from "./layouts/PageLayout.vue";

// Define routes with its own file
import { loginRoutes } from "@/routes/loginRoutes";
import { userAdminRoutes } from "@/routes/userAdminRoutes";
import { orderRoutes } from "@/routes/orderRoutes";

// Import services
import orderService from "@/services/OrderService";
import { useAuthStore } from "@/store/auth";
import { timeslotRoutes } from "@/routes/timeslotRoutes";
import { settingsRoutes } from "./routes/settingsRoutes";

// Define routes
const routes: RouteRecordRaw[] = [
    // Redirect index to dashboard
    {
        path: "/",
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            // If we are authenticated, go to the order overview by default
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                return { path: "/orders/overview" };
            }

            // If we are not authenticated, go to the login screen
            return { path: "/login" };
        },
    },
    ...loginRoutes,
    {
        path: "/orders/sumupcallback/:id",
        redirect: (to) => {
            // If the sumup payment was successful
            if (to.query["smp-status"] === "success") {
                // Trigger an api sync of the sumup transaction in the order
                orderService.updateSumupTransaction(
                    to.query["smp-tx-code"] as string,
                );
            }

            // Return to the order detail page of the order
            return { path: `/orders/${to.params.id}`, query: undefined };
        },
    },
    // Main routing
    {
        path: "/",
        component: PageLayout,
        children: [
            ...orderRoutes,
            ...userAdminRoutes,
            ...timeslotRoutes,
            ...settingsRoutes,
        ],
    },
];

// Create router instance
const Router = createRouter({
    history: createWebHistory(),
    routes,
} as RouterOptions);

Router.beforeEach((to) => {
    if (to.meta.requiresAuth && !isAuthenticated())
        return { name: "selectInstance", query: { redirect: to.fullPath } };
    if (
        (to.meta.roles?.length ?? 0) > 0 &&
        !userHasRoles(to.meta.roles as string[])
    ) {
        return false;
    }
    if (!to.meta.onProd && import.meta.env.PROD) return false;

    return true;
});

function isAuthenticated() {
    const authStore = useAuthStore();
    return authStore.isAuthenticated;
}

function userHasRoles(roles: string[]) {
    const authStore = useAuthStore();
    if (roles.length === 0) return true;
    for (const role of roles) {
        if (authStore.roles.includes(role)) return true;
    }
    return false;
}

// Typing of the RouteMeta fields
declare module "vue-router" {
    interface RouteMeta {
        requiresAuth: boolean;
        roles?: string[];
        onProd: boolean;
    }
}

// Export our router
export default Router;
