<script setup lang="ts">
    import DeliveryStatusChip from "@/components/common/chips/DeliveryStatusChip.vue";
    import PaymentMethodChip from "@/components/common/chips/PaymentMethodChip.vue";
    import PaymentStatusChip from "@/components/common/chips/PaymentStatusChip.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import { computed } from "vue";

    const props = defineProps<{
        order: OrderDetails;
    }>();
    const formattedTelephone = computed(() => {
        const phone = props.order.customer.telephone;
        return "0" + phone.slice(3);
    });
</script>

<template>
    <v-card class="mb-5" title="Bestel informatie">
        <v-card-text>
            <v-table theme="light">
                <tbody>
                    <tr>
                        <td>Bestelnummer</td>
                        <td>
                            {{ order.orderId }}
                        </td>
                    </tr>
                    <tr>
                        <td>Naam klant</td>
                        <td>{{ order.customer.name }}</td>
                    </tr>
                    <tr>
                        <td>Opmerking(Klant)</td>
                        <td>
                            {{ order.remarks.customerRemark || "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td>Opmerking(Systeem)</td>
                        <td>
                            {{ order.remarks.systemRemark || "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td>Telefoon</td>
                        <td>
                            <a
                                v-if="order.customer.telephone"
                                :href="'tel:' + formattedTelephone"
                                class="text-black"
                            >
                                {{ formattedTelephone }}
                            </a>
                            <template v-else>-</template>
                        </td>
                    </tr>
                    <tr>
                        <td>Mailadres</td>
                        <td>
                            <a
                                v-if="order.customer.email"
                                :href="'mailto:' + order.customer.email"
                                class="text-black"
                            >
                                {{ order.customer.email }}
                            </a>
                            <template v-else>-</template>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalmethode</td>
                        <td>
                            <PaymentMethodChip
                                :method="order.paymentMethod"
                                text="label"
                            ></PaymentMethodChip>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalstatus</td>
                        <td>
                            <payment-status-chip
                                :status="order.paymentStatus"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Bezorgstatus</td>
                        <td>
                            <delivery-status-chip
                                :delivered="order.state.delivered"
                                :deliver="order.deliver"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Verantwoordelijke</td>
                        <td v-if="order.responsibleUser">
                            {{ order.responsibleUser?.fullName }}
                        </td>
                        <td v-else>Geen verantwoordelijke</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
