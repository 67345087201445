<script setup lang="ts">
    import { DeliveryFullfilment } from "@/models/OrderDetails";

    import { computed, defineProps } from "vue";

    const props = defineProps<{
        fulfillment: DeliveryFullfilment;
    }>();

    const googleMapsAddressUrl = computed(() => {
        return encodeURI(
            "https://maps.google.com/maps?q=" +
                props.fulfillment.street +
                " " +
                props.fulfillment.houseNumber +
                props.fulfillment.houseNumberSuffix +
                " " +
                props.fulfillment.postalCode +
                " " +
                props.fulfillment.city,
        );
    });
</script>

<template>
    <tr>
        <td>Adres</td>
        <td>
            <a :href="googleMapsAddressUrl" target="_blank" class="text-black">
                {{ fulfillment.street ?? "" }}
                {{ fulfillment.houseNumber ?? -1
                }}{{ fulfillment.houseNumberSuffix }}
            </a>
        </td>
    </tr>
    <tr>
        <td>Postcode</td>
        <td>
            <a :href="googleMapsAddressUrl" target="_blank" class="text-black">
                {{ fulfillment.postalCode }},
                {{ fulfillment.city }}
            </a>
        </td>
    </tr>
    <tr>
        <td>Wijk</td>
        <td>{{ fulfillment.area.name }}</td>
    </tr>
    <tr>
        <td>Wijkgroep</td>
        <td>{{ fulfillment.areaGroup.name }}</td>
    </tr>
</template>

<style scoped></style>
