import axios, { AxiosError, isAxiosError } from "axios";
import { useAuthStore } from "@/store/auth";
import Router from "@/router";
import { NotificationGroup, useNotificationStore } from "@/store/notification";
import authService from "./AuthenticationService";
import { useInstanceStore } from "@/store/instance";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
    const instanceStore = useInstanceStore();
    config.baseURL = instanceStore.apiUrl;
    return config;
});

// Add the authorization header
axiosInstance.interceptors.request.use(async (config) => {
    const authStore = useAuthStore();
    // Don't try adding an access token if we don't have one.
    if (!authStore.accessToken) return config;

    // If our access token is expired, refresh first.
    if (authStore.accessToken.expiry < Date.now() / 1000) {
        await debounceRefresh();
    }

    //  When it expires we should first get a new AccessToken (via a refreshtoken or something) or kick out the user.
    config.headers["Authorization"] = "Bearer " + authStore.accessToken?.token;
    return config;
});

const debounceRefreshClosure = () => {
    let isCalled = false;
    let refreshCall: Promise<void> | undefined = undefined;
    return () => {
        if (isCalled) {
            return refreshCall;
        } else {
            isCalled = true;
            refreshCall = authService
                .refresh()
                // Logout logic is here because authservice does not use the api service
                .catch(async (error) => {
                    if (isAxiosError(error) && error.response?.status === 401) {
                        await Router.push({
                            name: "logout",
                            query: {
                                redirect: Router.currentRoute.value.fullPath,
                            },
                        });
                    }
                })
                .finally(() => {
                    refreshCall = undefined;
                    isCalled = false;
                });
            return refreshCall;
        }
    };
};
const debounceRefresh = debounceRefreshClosure();

// When the response is an access token expired error, we should refresh it and try again
// If we can't refresh the access token and the connection is still fine, we can assume something is wrong with the refresh token
// and we should logout the user.
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const request = error.config;

        // If there is no request nor a response, we dont't care about it in this interceptor
        if (!request || !error.response) {
            return Promise.reject(error);
        }

        // We only care about 401 errors for this interceptor
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        if (
            request.url === "/api/v1/auth/logout" ||
            Router.currentRoute.value.path == "/login"
        ) {
            // Ignore the 401 when we are logging out or at the login screen
            return Promise.reject(error);
        }

        // The 401 occurred when we have logged in.
        // Refresh the token
        await debounceRefresh();

        // Retry the request with the new token
        const authStore = useAuthStore();
        request.headers["Authorization"] =
            "Bearer " + authStore.accessToken?.token;
        return axiosInstance(request);
    },
);

// When there is a network error, we should notify the user that something went wrong.
axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (axios.isAxiosError(error) && error.code === "ERR_NETWORK") {
            const notificationStore = useNotificationStore();
            notificationStore.addNotification(
                "Kan geen verbinding maken. Controleer je internetverbinding en probeer het opnieuw.",
                "warning",
                "mdi-wifi-strength-alert-outline",
                undefined,
                NotificationGroup.ConnectionError,
            );
        }

        return Promise.reject(error);
    },
);

// When the response is a 500 Error, we should notify the user that something went wrong.
axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response?.status === 500) {
            const notificationStore = useNotificationStore();
            notificationStore.addNotification(
                "Er is een onverwachte fout opgetreden. Probeer het later opnieuw. Blijft het probleem zich voordoen? Neem dan contact op met de beheerder.",
                "error",
                undefined,
                undefined,
                NotificationGroup.ApiInternalError,
            );
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
