import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
// 1. Add Vuetify
import vuetify from "./plugins/vuetify";
// 3. Add our router implementation
import router from "./router";
import { createPinia } from "pinia";
import { Zettle } from "@globy-app/zettle";

// Create our app object
const app = createApp(App);

app.use(vuetify);

// 2. Add Pinia
// It is important that Pinia is loaded before the router, as the router depends on values in the store.
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(router);

// 4. Add Sentry
Sentry.init({
    app,
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.APP_ENV,
    integrations: [
        // new Sentry.BrowserTracing(),
        new Sentry.Replay(),
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

if (Capacitor.isNativePlatform()) {
    console.log("Running on native platform");
    // 5. Add capacitor url handling (deep linking)
    CapacitorApp.addListener("appUrlOpen", (data) => {
        const reg = new RegExp(
            `^(${import.meta.env.APP_ID}://application)|(${import.meta.env.PUBLIC_URL})`,
            "ig",
        );
        const routerUrl = data.url.replace(reg, "");

        router.push(routerUrl);
    });

    // Initalize the zettle plugin
    Zettle.initialize({
        developermode: import.meta.env.ZETTLE_DEVELOPERMODE === "true",
    });
}

// Add our dependencies to our app object

app.mount("#app");
