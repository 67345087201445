<script lang="ts" setup>
    import { computed, unref } from "vue";
    import { VAlert } from "vuetify/components";
    import { useOrderCreationStore } from "@/store/orderCreation";
    import FulfillmentStep from "@/components/orderPlacement/steps/FulfillmentStep.vue";
    import { formatPrice } from "@/composables/formatter";
    import CustomerInformationStep from "@/components/orderPlacement/steps/CustomerInformationStep.vue";
    import TimeslotStep from "@/components/orderPlacement/steps/TimeslotStep.vue";
    import ProductStep from "@/components/orderPlacement/steps/ProductStep.vue";
    import checkoutService from "@/services/Checkout/CheckoutService";
    import { onBeforeRouteLeave, useRouter } from "vue-router";
    import CustomFields from "@/components/orderPlacement/customFields/CustomFields.vue";
    import RemarkStep from "@/components/orderPlacement/steps/RemarkStep.vue";
    import PaymentStep from "@/components/orderPlacement/steps/PaymentStep.vue";
    import DonationStep from "@/components/orderPlacement/steps/DonationStep.vue";
    import PermissionStep from "@/components/orderPlacement/steps/PermissionStep.vue";
    import { useNotificationStore } from "@/store/notification";
    import { item } from "@/store/orderCreation/types";
    import useVuelidate from "@vuelidate/core";
    import DiscountStep from "@/components/orderPlacement/steps/DiscountStep.vue";

    const orderStore = useOrderCreationStore();
    const router = useRouter();

    const v$ = useVuelidate();

    // Ask the user for confirmation
    onBeforeRouteLeave((to) => {
        if (to.name !== "orderDetail") {
            return window.confirm(
                "Weet je zeker dat je het bestelformulier wilt verlaten? Alle gegevens die je hebt ingevuld gaan verloren.",
            );
        }
    });

    const allowProcessOrder = computed(() => {
        // Check if there are timeslots available
        //  If the amount of avaiable products is 0, show an error as that means there are no free timeslots
        if (orderStore.getAvailableTimeslots.length == 0) {
            return false;
        }

        if (!orderStore.locationValid) {
            return false;
        }

        return true;
    });

    const notificationStore = useNotificationStore();

    const processOrder = async () => {
        if (!(await v$.value.$validate())) {
            notificationStore.addNotification(
                "Er zitten nog fouten in het formulier. Controleer de rode velden.",
                "error",
            );
            return;
        }

        const response = await checkoutService.postOrder(
            unref(orderStore.getPostOrderDTO),
        );
        if (response.status == 200) {
            await router.push("/orders/" + response.data.orderId);
            return;
        }

        const error_code = response.data.error_code;

        if (error_code == -403) {
            notificationStore.addNotification(
                "Het gekozen tijdvak is gevuld door iemand anders. Kies een ander tijdvak.",
                "error",
            );
            return;
        }

        if (error_code == -407) {
            const outOfStockProduct =
                orderStore.options.productOptions.products.find(
                    (product) =>
                        product.id === response.data.error_ident.product,
                ) as item;
            notificationStore.addNotification(
                `Helaas is ${outOfStockProduct.label} uitverkocht (in dit tijdvak).`,
                "error",
            );
            return;
        }

        notificationStore.addNotification(
            "Er is iets fout gegaan bij het plaatsen van de bestelling. Probeer het later opnieuw.",
            "error",
        );
    };
</script>

<template>
    <v-card-text>
        <v-container fluid>
            <v-row
                v-if="
                    orderStore.options.alert &&
                    orderStore.options.alert.text &&
                    orderStore.options.alert.type
                "
            >
                <v-slide-y-transition>
                    <v-col>
                        <template v-if="orderStore.options.alert">
                            <v-alert
                                :text="orderStore.options.alert.text"
                                :type="orderStore.options.alert.type"
                            />
                        </template>
                    </v-col>
                </v-slide-y-transition>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-form>
                        <CustomerInformationStep />
                        <FulfillmentStep />
                        <CustomFields type="pre" />
                        <ProductStep />
                    </v-form>
                </v-col>
                <v-divider
                    class="border-opacity-50 d-none d-md-inline-flex"
                    vertical
                />
                <v-col>
                    <v-form>
                        <TimeslotStep />
                        <CustomFields type="post" />
                        <RemarkStep />
                        <PaymentStep />
                        <DiscountStep />
                        <DonationStep />
                        <PermissionStep />

                        <v-divider class="border-opacity-100" />
                        <div
                            class="text-body-1 font-weight-bold pt-3"
                            data-cy="total"
                        >
                            Totaalprijs:
                            {{ formatPrice(orderStore.orderTotal) }}
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer />
        <v-btn
            :disabled="!allowProcessOrder"
            color="blue"
            data-cy="place-order"
            variant="tonal"
            @click="processOrder"
            >Bestelling plaatsen
        </v-btn>
    </v-card-actions>
</template>

<style lang="scss" scoped></style>
