<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import { OrderPermissions } from "@/models/OrderPermissions";
    import { computed } from "vue";

    const props = defineProps<{
        order: OrderDetails;
        enablePin: boolean;
        enablePrint: boolean;
        enableEdit: boolean;
        permissions: OrderPermissions;
    }>();

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "print"): void;
        // eslint-disable-next-line no-unused-vars
        (e: "pin"): void;
        // eslint-disable-next-line no-unused-vars
        (e: "delete"): void;
        // eslint-disable-next-line no-unused-vars
        (e: "edit"): void;
        // eslint-disable-next-line no-unused-vars
        (e: "paid"): void;
        // eslint-disable-next-line no-unused-vars
        (e: "delivered"): void;
    }>();

    const markDeliveredText = computed(() => {
        if (props.order.deliver) {
            return props.order.state.delivered
                ? "Bezorgen ongedaan maken"
                : "Markeer bezorgd";
        } else {
            return props.order.state.delivered
                ? "Ophalen ongedaan maken"
                : "Markeer afgehaald";
        }
    });
</script>

<template>
    <v-card title="Acties">
        <v-card-text>
            <template v-if="!props.order.state.deleted">
                <v-btn
                    v-if="props.enablePrint"
                    class="ma-2 mx-md-2"
                    color="primary"
                    prepend-icon="mdi-printer"
                    text="Printen"
                    @click="() => emit('print')"
                />
                <v-btn
                    v-if="props.permissions.state.delivered"
                    class="ma-2 mx-md-2"
                    color="primary"
                    prepend-icon="mdi-van-utility"
                    :text="markDeliveredText"
                    @click="() => emit('delivered')"
                />
                <v-btn
                    v-if="props.permissions.state.paid"
                    class="ma-2 mx-md-2"
                    color="primary"
                    prepend-icon="mdi-cash"
                    :text="
                        props.order.paymentStatus == 'open'
                            ? 'Als betaald markeren'
                            : 'Als open markeren'
                    "
                    @click="() => emit('paid')"
                />
                <v-btn
                    v-if="props.enablePin"
                    class="ma-2 mx-md-2"
                    color="primary"
                    prepend-icon="mdi-contactless-payment"
                    text="Pinnen"
                    @click="() => emit('pin')"
                />

                <v-btn
                    v-if="props.enableEdit"
                    class="ma-2 mx-md-2"
                    color="primary"
                    prepend-icon="mdi-pen"
                    text="Bestelling aanpassen"
                    @click="() => emit('edit')"
                />
            </template>
            <template v-else-if="!props.permissions.state.deleted">
                <p>
                    Vraag iemand van de organisatie om deze bestelling als niet
                    verwijderd te markeren.
                </p>
            </template>
            <v-btn
                v-if="props.permissions.state.deleted"
                class="ma-2 mx-md-2"
                color="red"
                prepend-icon="mdi-delete"
                :text="
                    props.order.state.deleted
                        ? 'Verwijderen ongedaan maken'
                        : 'Verwijderen'
                "
                @click="() => emit('delete')"
            />
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
