<script lang="ts" setup>
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import { computed, onMounted, onUnmounted, ref } from "vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import OrderPreview from "@/components/orders/scanner/OrderPreview.vue";
    import OrderList from "@/components/orders/scanner/OrderList.vue";
    import OrderMap from "@/components/orders/scanner/OrderMap.vue";
    import OrderService from "@/services/OrderService";
    import UserService, { User } from "@/services/UserService";
    import { useDisplay } from "vuetify";
    import { StreamBarcodeReader } from "vue-barcode-reader";

    const searchInputRef = ref();
    const searchValue = ref();

    //-- Auto focus on the text field when the user types a number.
    const keydownCallback = (event: KeyboardEvent) => {
        // If the user types a number, focus the search input.
        if (event.key.match(/[0-9]/)) {
            searchInputRef.value.focus();
        }
    };

    onMounted(() => {
        // Start listener
        document.addEventListener("keydown", keydownCallback, false);
    });

    onUnmounted(() => {
        // Stop listener
        document.removeEventListener("keydown", keydownCallback);
    });

    //-- Scanning state
    const loading = ref(false);
    const error = ref(false);

    const scannedOrders = ref<OrderDetails[]>([]);
    const currentOrder = ref<OrderDetails | undefined>(undefined);

    const deliveryOrders = computed(() =>
        scannedOrders.value.filter((order) => order.deliver),
    );

    const addOrder = async () => {
        if (loading.value) {
            return;
        }

        loading.value = true;
        try {
            const id: number = parseInt(searchValue.value.slice(0, 4));
            const { data: order } = await OrderService.getOrderById(id);
            currentOrder.value = order;
            const duplicate = scannedOrders.value.find(
                (o) => o.id === order.id,
            );
            if (duplicate) {
                return; // Skip if already added.
            }

            scannedOrders.value.push(order);
        } catch {
            error.value = true;
        } finally {
            loading.value = false;
            searchValue.value = "";
        }
    };

    const emptyList = () => {
        scannedOrders.value = [];
        currentOrder.value = undefined;
    };

    const removeOrder = (id: number) => {
        scannedOrders.value = scannedOrders.value.filter(
            (order) => order.id !== id,
        );
    };

    //-- Assignment of people to orders
    const selectedUser = ref<number | undefined>();
    const users = ref<User[]>([]);
    const assigningOrders = ref(false);

    onMounted(async () => {
        const { data } = await UserService.getUsers();
        users.value = data;
    });

    const assignOrders = async () => {
        assigningOrders.value = true;
        try {
            if (!selectedUser.value) {
                return;
            }

            for (const order of scannedOrders.value) {
                await OrderService.assignOrder(order.id, selectedUser.value);
                removeOrder(order.id);
            }

            emptyList();
            selectedUser.value = undefined;
        } finally {
            assigningOrders.value = false;
        }
    };

    // ----- Camera for phones
    const cameraDialog = ref(false);
    const cameraCallback = (orderNumber: string) => {
        searchValue.value = orderNumber;
        addOrder();
        cameraDialog.value = false;
    };

    const { mdAndUp } = useDisplay();
</script>

<template>
    <v-container fluid>
        <globy-app-bar title="Centrale post scanner">
            <template #actions>
                <v-btn variant="tonal" @click="cameraDialog = true">
                    <v-icon>mdi-camera</v-icon>
                </v-btn>
            </template>
        </globy-app-bar>
        <v-dialog
            v-model="cameraDialog"
            max-width="500px"
            persistent
            width="auto"
        >
            <v-card class="rounded-lg">
                <v-card-title class="bg-primary">Camera</v-card-title>
                <v-card-text>
                    <StreamBarcodeReader @decode="cameraCallback" />
                </v-card-text>
                <v-card-actions
                    ><v-btn @click="cameraDialog = false"
                        >Afsluiten</v-btn
                    ></v-card-actions
                >
            </v-card>
        </v-dialog>

        <v-row class="flex-nowrap">
            <v-col v-if="mdAndUp">
                <v-card class="mb-5">
                    <v-card-title> Bestelling</v-card-title>
                    <v-card-text>
                        <v-alert v-if="!currentOrder">
                            Scan een bestelling om deze aan een bezorger toe te
                            wijzen.
                        </v-alert>
                        <order-preview v-else :order="currentOrder" />
                    </v-card-text>
                </v-card>
                <v-card v-if="deliveryOrders.length > 0" title="Bezorgkaart">
                    <v-card-text>
                        <order-map
                            v-if="deliveryOrders.length > 0"
                            :orders="deliveryOrders"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mb-5">
                    <v-card-text>
                        <v-text-field
                            ref="searchInputRef"
                            v-model="searchValue"
                            :error="error"
                            :loading="loading"
                            autofocus
                            density="compact"
                            hide-details
                            label="Bestelnummer"
                            variant="outlined"
                            @keyup.enter="addOrder"
                        >
                            <template #append>
                                <v-btn
                                    :disabled="loading"
                                    color="primary"
                                    variant="outlined"
                                    @click="addOrder"
                                >
                                    Toevoegen
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-card-text>
                </v-card>
                <v-card>
                    <order-list
                        :orders="scannedOrders"
                        @remove-order="removeOrder"
                    />
                    <v-card-actions>
                        <v-autocomplete
                            v-model="selectedUser"
                            :disabled="assigningOrders"
                            :items="users"
                            :loading="assigningOrders"
                            density="compact"
                            item-title="fullName"
                            item-value="id"
                            label="Bezorger"
                            variant="outlined"
                        >
                            <template #append>
                                <v-btn
                                    :disabled="
                                        scannedOrders.length == 0 ||
                                        assigningOrders
                                    "
                                    class="mr-3"
                                    color="error"
                                    prepend-icon="mdi-trash-can"
                                    variant="tonal"
                                    @click="emptyList"
                                >
                                    Leegmaken
                                </v-btn>
                                <v-btn
                                    :disabled="
                                        scannedOrders.length == 0 ||
                                        assigningOrders
                                    "
                                    color="primary"
                                    variant="flat"
                                    @click="assignOrders"
                                >
                                    Toewijzen
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
