import Index from "@/views/settings/Index.vue";
import { RouteRecordRaw } from "vue-router";

export const settingsRoutes: RouteRecordRaw[] = [
    {
        path: "/settings",
        component: Index,
        name: "settings-index",
        meta: {
            requiresAuth: true,
            onProd: true,
            roles: ["ROLE_USER"],
        },
    },
];
