import ApiService from "@/services/ApiService";

export interface User {
    readonly id: number;
    readonly fullName: string;
}

const apiService = ApiService;

const userService = {
    getUsers: () => {
        return apiService.get<User[]>("/api/v1/user");
    },
};

export default userService;
