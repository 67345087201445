<script lang="ts" setup>
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import { decimal, minValue } from "@/composables/dutchVuelidate";
    import { helpers } from "@vuelidate/validators";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { onMounted, reactive, ref } from "vue";
    import { vMaska } from "maska/vue";
    import { MaskInputOptions } from "maska";

    const orderStore = useOrderCreationStore();
    const fieldModel = ref<number | string | undefined>();
    const discountMask = reactive<MaskInputOptions>({
        onMaska: (value) => {
            if (!isNaN(parseFloat(value.unmasked))) {
                orderStore.order.toggleableFields.discount = parseFloat(
                    value.unmasked,
                );
            }
        },
        number: {
            locale: "nl",
            fraction: 2,
            unsigned: true,
        },
    });

    onMounted(() => {
        fieldModel.value = orderStore.order.toggleableFields.discount;
    });

    const v$ = useVuelidate(
        {
            fieldModel: {
                positiveOrderTotal: helpers.withMessage(
                    "De totale prijs moet boven 0 blijven",
                    () => orderStore.orderTotal >= 0,
                ),
                mustBePositive: minValue(0),
                decimal: decimal(2),
                // Max value is handled by the order total must be positive validation
            },
        },
        {
            fieldModel,
        },
    );
</script>

<template>
    <template v-if="orderStore.options.toggleableFields.discount?.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">Korting</div>
        <v-text-field
            v-model="fieldModel"
            v-maska="discountMask"
            :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
            density="compact"
            placeholder="Korting"
            prepend-inner-icon="mdi-currency-eur"
            variant="outlined"
            @focusout="
                () => {
                    v$.fieldModel.$validate();
                }
            "
        />
    </template>
</template>

<style scoped></style>
