import OrdersOverviewPage from "@/views/orders/OrdersOverviewPage.vue";
import OrderDetailsPage from "@/views/orders/OrderDetailsPage.vue";
import OrderCreatePage from "@/views/orders/OrderCreatePage.vue";
import OrderEditPage from "@/views/orders/OrderEditPage.vue";
import BulkScannerPage from "@/views/orders/BulkScannerPage.vue";
import ScannerPage from "@/views/orders/ScannerPage.vue";

export const orderRoutes = [
    {
        path: "/orders/overview",
        component: OrdersOverviewPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/create",
        component: OrderCreatePage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/scan",
        component: ScannerPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/bulk-scan",
        component: BulkScannerPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/:id",
        name: "orderDetail",
        component: OrderDetailsPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/:id/edit",
        name: "orderEdit",
        component: OrderEditPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
];
