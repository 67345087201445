import { OrderDetails } from "@/models/OrderDetails";
import { getSumupUrl } from "@/services/PinServices/SumupService";

import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { v4 as uuidv4 } from "uuid";
import { Zettle } from "@globy-app/zettle";

export const startPayment = async (
    order: OrderDetails,
): Promise<{ success?: boolean }> => {
    if (!["zettle", "sumup"].includes(order.paymentMethod.service)) {
        console.error(
            `Payment method service ${order.paymentMethod.service} not supported in payment service`,
        );
        return Promise.reject("Payment method not supported");
    }

    const fxTxId = order.id + "-" + uuidv4();
    const orderTotalEuros = order.priceFactors.orderTotal / 100;

    if (order.paymentMethod.service === "sumup") {
        const callbackHost = Capacitor.isNativePlatform()
            ? `${import.meta.env.APP_ID}://application`
            : import.meta.env.PUBLIC_URL;

        const url = getSumupUrl(
            orderTotalEuros,
            order.orderId,
            fxTxId,
            callbackHost,
        );

        console.log("Opening Sumup URL", url);

        await AppLauncher.openUrl({ url });
        return Promise.resolve({ success: undefined });
    }

    if (order.paymentMethod.service === "zettle") {
        const result = await Zettle.chargeAmount({
            amount: orderTotalEuros,
            reference: "ord-" + fxTxId,
        });

        return Promise.resolve({ success: result.success });
    }

    return Promise.reject("Payment method not supported");
};

export const isPaymentSupported = (order: OrderDetails): boolean => {
    if (!["zettle", "sumup"].includes(order.paymentMethod.service)) {
        return false;
    }

    // Sumup is only supported on mobile
    if (order.paymentMethod.service === "sumup") {
        // FIXME: We can't seem to support sumup on ios :(
        return Capacitor.getPlatform() === "android";
    }

    // Zettle is only supported on mobile
    if (order.paymentMethod.service === "zettle") {
        return Capacitor.isNativePlatform();
    }

    // Should not happen
    return false;
};
