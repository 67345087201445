<script lang="ts" setup>
    import type { OrderDetails } from "@/models/OrderDetails";

    const { orders = [] } = defineProps<{
        orders: OrderDetails[];
    }>();

    const emits = defineEmits<{
        removeOrder: [id: number];
    }>();
</script>

<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th style="width: 10em">Bestelnummer</th>
                <th>Klant</th>
                <th>Adres/Afhaalpunt</th>
                <th style="width: 12px"></th>
            </tr>
        </thead>
        <tbody v-if="orders.length > 0">
            <tr v-for="order in orders" :key="order.id">
                <td>{{ order.orderId }}</td>
                <td>{{ order.customer.name }}</td>
                <td v-if="order.deliver">
                    {{ order.fulfillment.street }}
                    {{ order.fulfillment.houseNumber
                    }}{{ order.fulfillment.houseNumberSuffix }}
                </td>
                <td v-if="!order.deliver">
                    {{ order.fulfillment.label }}
                </td>
                <td>
                    <v-btn
                        color="error"
                        variant="text"
                        @click="emits('removeOrder', order.id)"
                    >
                        <v-icon icon="mdi-trash-can" />
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-table>
    <v-empty-state
        v-if="orders.length == 0"
        icon="mdi-van-utility"
        title="Nog geen bestellingen gescand"
    />
</template>

<style scoped></style>
