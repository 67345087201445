<script lang="ts" setup>
    import { onMounted, ref } from "vue";
    import { useOverviewFilterStore } from "@/store/orderOverview";
    import PaymentStatusChip from "@/components/common/chips/PaymentStatusChip.vue";
    import paymentMethodService from "@/services/PaymentMethodService";
    import PaymentMethodChip from "@/components/common/chips/PaymentMethodChip.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import timeslotService, { Timeslot } from "@/services/TimeslotService";
    import { AxiosResponse } from "axios";
    import { useAuthStore } from "@/store/auth";
    import { Capacitor } from "@capacitor/core";
    import userService, { User } from "@/services/UserService";

    const authStore = useAuthStore();
    const filterStore = useOverviewFilterStore();
    const paymentStatuses = [
        {
            title: "Open",
            value: "open",
        },
        {
            title: "Betaald",
            value: "paid",
        },
        {
            title: "Mislukt",
            value: "failed",
        },
        {
            title: "In afwachting",
            value: "pending",
        },
        {
            title: "Verlopen",
            value: "expired",
        },
        {
            title: "Terug betaald",
            value: "refunded",
        },
    ];
    const printStatusChoices = [
        {
            title: "Alles",
            value: "all",
        },
        {
            title: "Geprint",
            value: "true",
        },
        {
            title: "Niet geprint",
            value: "false",
        },
    ];
    const deliverStatusChoices = [
        {
            title: "Alles",
            value: "all",
        },
        {
            title: "Afgeleverd",
            value: "true",
        },
        {
            title: "Niet afgeleverd",
            value: "false",
        },
    ];
    const deletedChoices = [
        {
            title: "Niet verwijderd",
            value: "false",
        },
        {
            title: "Verwijderd",
            value: "true",
        },
    ];
    const fulfilmentTypeChoices = [
        {
            title: "Alles",
            value: "all",
        },
        {
            title: "Afhalen",
            value: "pickup",
        },
        {
            title: "Bezorgen",
            value: "delivery",
        },
    ];

    const paymentMethods = ref<OrderDetails["paymentMethod"][]>([]);

    const timeslots = ref<Timeslot[]>([]);

    const users = ref<User[]>([]);

    onMounted(async () => {
        paymentMethodService
            .getAll()
            .then(
                (response: AxiosResponse<OrderDetails["paymentMethod"][]>) => {
                    paymentMethods.value = response.data;
                },
            );

        if (
            authStore.hasRole("ROLE_DELIVERER_MANAGER") ||
            authStore.hasRole("ROLE_ADMIN")
        ) {
            users.value = (await userService.getUsers()).data;
        }

        timeslots.value = await timeslotService.getAll();
    });

    const resetSlots = () => {
        filterStore.filter.timeslot.slots = [];
    };

    const emit = defineEmits<{
        // eslint-disable-next-line no-unused-vars
        (e: "onPrint"): void;
    }>();

    const expand = ref<boolean>(false);
</script>

<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="w-100 justify-start"
                color="primary"
                prepend-icon="mdi-filter-outline"
                size="large"
                text="Filters"
                variant="tonal"
                @click="expand = !expand"
            />
        </v-card-title>

        <v-expand-transition>
            <div v-show="expand">
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn
                                v-if="!Capacitor.isNativePlatform()"
                                class="justify-start ml-2"
                                color="purple"
                                prepend-icon="mdi-printer"
                                text="Printen"
                                variant="tonal"
                                @click="emit('onPrint')"
                            />
                            <v-btn
                                class="justify-start ml-2"
                                color="red"
                                prepend-icon="mdi-close-circle"
                                text="Filters resetten"
                                variant="tonal"
                                @click="filterStore.resetFilter"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-select
                                v-model="filterStore.filter.paymentStatus"
                                :items="paymentStatuses"
                                density="compact"
                                label="Betaalstatus"
                                multiple
                                variant="outlined"
                            >
                                <template #selection="{ item }">
                                    <payment-status-chip :status="item.value" />
                                </template>
                            </v-select>
                            <v-select
                                v-model="filterStore.filter.paymentMethod"
                                :items="paymentMethods"
                                density="compact"
                                item-title="label"
                                item-value="id"
                                label="Betaalmethodes"
                                multiple
                                variant="outlined"
                            >
                                <template #selection="{ item }">
                                    <payment-method-chip :method="item.raw" />
                                </template>
                            </v-select>

                            <v-row>
                                <v-col cols="9">
                                    <v-select
                                        v-model="
                                            filterStore.filter.timeslot.day
                                        "
                                        :item-title="
                                            (item: Timeslot) => {
                                                return new Date(
                                                    item.day,
                                                ).toLocaleDateString();
                                            }
                                        "
                                        :items="timeslots"
                                        clearable
                                        density="compact"
                                        label="Dag"
                                        return-object
                                        variant="outlined"
                                        @click:clear="resetSlots"
                                        @update:model-value="resetSlots"
                                    />
                                </v-col>
                                <v-col
                                    cols=" 3
                                    "
                                >
                                    <v-select
                                        v-model="
                                            filterStore.filter.timeslot.slots
                                        "
                                        :disabled="
                                            !filterStore.filter.timeslot.day
                                        "
                                        :items="filterStore.getDaySlots"
                                        density="compact"
                                        item-title="label"
                                        item-value="id"
                                        label="Tijdvak"
                                        multiple
                                        no-data-text="Kies eerst een dag"
                                        variant="outlined"
                                    >
                                        <template #selection="{ index }">
                                            <template
                                                v-if="
                                                    filterStore.filter.timeslot
                                                        .slots.length === 1
                                                "
                                            >
                                                1 tijdvak
                                            </template>
                                            <template v-if="index == 1">
                                                {{
                                                    filterStore.filter.timeslot
                                                        .slots.length
                                                }}
                                                tijdvakken
                                            </template>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-autocomplete
                                v-if="
                                    authStore.hasRole(
                                        'ROLE_DELIVERER_MANAGER',
                                    ) || authStore.hasRole('ROLE_ADMIN')
                                "
                                v-model="filterStore.filter.responsibleUser"
                                :items="users"
                                item-title="fullName"
                                clearable
                                item-value="id"
                                density="compact"
                                label="Verantwoordelijke"
                                variant="outlined"
                            />
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="filterStore.filter.printStatus"
                                :items="printStatusChoices"
                                density="compact"
                                label="Printstatus"
                                variant="outlined"
                            />
                            <v-select
                                v-model="filterStore.filter.deliveryStatus"
                                :items="deliverStatusChoices"
                                density="compact"
                                label="Leverstatus"
                                variant="outlined"
                            />
                            <v-select
                                v-model="filterStore.filter.fulfilmentType"
                                :items="fulfilmentTypeChoices"
                                density="compact"
                                label="Aflevertype"
                                variant="outlined"
                            />
                            <v-select
                                v-model="filterStore.filter.deleted"
                                :items="deletedChoices"
                                density="compact"
                                label="Verwijderde bestellingen"
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="authStore.hasRole('ROLE_ADMIN')">
                        <v-col>
                            <v-text-field
                                v-model="filterStore.filter.customQuery"
                                density="compact"
                                label="Geadvanceerd filteren"
                                prepend-icon="mdi-alert-outline"
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<style scoped></style>
