import { defineStore } from "pinia";
import {
    customFieldDto,
    deliveryDay,
    deliverySlot,
    displayItem,
    OrderDTO,
    productBind,
    productDto,
    State,
} from "@/store/orderCreation/types";
import checkoutService from "@/services/Checkout/CheckoutService";
import { nextTick, unref } from "vue";
import { formatIntlTelephone } from "@/composables/formatter";
import { useNotificationStore } from "@/store/notification";
import { klona } from "klona";

export const useOrderCreationStore = defineStore("orderCreation", {
    state: (): State =>
        <State>{
            order: {},
            options: {},
            locationValid: false,
            locationFee: 0,
            binds: [] as productBind[],
            // This is _not_ updated when the underlying availableDays array is altered
            selectedDay: undefined,
        },
    actions: {
        async fetchOptions() {
            // Fetch options from the API
            return checkoutService.getOptions().then((response) => {
                this.options = response.data;

                this.normalizeOptions();
                this.resetOrder();
            });
        },
        selectService(serviceId: number | undefined) {
            if (serviceId == undefined) {
                this.order.products = [];
                return;
            }
            this.order.products = [
                {
                    id: serviceId,
                    amount: 1,
                },
            ];

            this.ensureTimeslotIntegrity();
        },
        updateProduct(productId: number, quantity: number) {
            const orderProduct = this.order.products.find(
                (product) => product.id === productId,
            );

            if (!orderProduct) {
                console.error(
                    "Edited product not found in order. When the order object is initialized / reset all products should have been added.",
                );
                return;
            }
            orderProduct.amount = quantity;

            this.ensureTimeslotIntegrity();
        },
        ensureProductIntegrity() {
            // If the location is not yet known, then there is no integrity check needed as it would all fail,
            // however that would prevent filling in products before choosing a location.
            if (!this.locationValid) {
                return;
            }

            let productResetForced = false;

            for (const bind of this.binds) {
                // Product is no longer available
                if (bind.timeslots.length == 0) {
                    this.updateProduct(bind.id, 0);
                    productResetForced = true;
                }
            }

            if (productResetForced) {
                const notificationStore = useNotificationStore();
                if (this.options.productOptions.mode == "PRODUCT") {
                    notificationStore.addNotification(
                        "Een van de gekozen producten is niet (meer) beschikbaar op deze locatie.",
                        "warning",
                    );
                } else {
                    notificationStore.addNotification(
                        "Dit bezoek kan niet (meer) geboekt worden op dit adres.",
                        "warning",
                    );
                }
            }
        },
        async ensureTimeslotIntegrity() {
            // If the location is not yet known, there is no integrity check needed
            if (!this.locationValid) {
                return;
            }

            // Make vue wait for the getAvailableDays update to finish
            await nextTick();
            // This call will set this.selectedDay to undefined if the day is no longer available
            this.selectedDay = this.getAvailableDays.find(
                (day) => day.date === this.selectedDay?.date,
            );
            // Make vue wait for the getAvailableTimeslots update to finish
            await nextTick();

            if (this.selectedDay) {
                // The day is still available. Check if maybe just the chosen slot is not available anymore.
                if (
                    !this.getAvailableTimeslots.find(
                        (value) => value.id == this.order.fulfillment.timeslot,
                    )
                ) {
                    // The selected timeslot is not available anymore
                    this.order.fulfillment.timeslot = undefined;
                }
            } else {
                // The day is not available, so the chosen timeslot is also per definition not available anymore.
                this.order.fulfillment.timeslot = undefined;
            }
        },
        setCustomField({ id, value }: customFieldDto) {
            // As we preload the array with the default values, we MUST find a value and thus can assume it as true
            const field: customFieldDto = this.order.customFields.find(
                (input) => id === input.id,
            ) as customFieldDto;
            field.value = value;
        },
        normalizeOptions() {
            // FIXME: The list of paymentMethods is first ordered so the highest ID is on the top. This will be replaced by a
            //  priority property in the future.
            this.options.paymentMethods.sort((a, b) => {
                return a.id - b.id;
            });

            for (const day of this.options.timeslots) {
                day.slots.sort((a, b) =>
                    new Date(a.start) < new Date(b.start) ? -1 : 1,
                );
            }

            // Sort the dates
            this.options.timeslots.sort((a, b) =>
                new Date(a.date) < new Date(b.date) ? -1 : 1,
            );

            // Sort the timeslots. Javascript is funny because it thinks 10:00 is smaller than 9:00.
            for (const timeslot of this.options.timeslots) {
                timeslot.slots.sort((a, b) =>
                    parseInt(a.start.slice(0, 4)) <
                    parseInt(b.start.slice(0, 4))
                        ? -1
                        : 1,
                );
            }
        },
        async updateBinds(binds: productBind[]) {
            this.binds = binds;
            this.ensureProductIntegrity();
            this.ensureTimeslotIntegrity();

            return;
        },
        setDelivery(value: boolean) {
            this.order.fulfillment.deliver = value;

            if (this.order.fulfillment.deliver) {
                this.order.fulfillment.data = {};
            } else {
                this.order.fulfillment.data = {
                    pickupPoint: this.options.pickupPoints[0].id,
                };
            }
        },
        resetOrder() {
            this.order = {} as OrderDTO;
            this.order.products = [];

            // Initialize the payment method with the first one of the list
            this.order.paymentMethod = this.options.paymentMethods[0].id;

            this.order.fulfillment = {
                timeslot: undefined,
                deliver: true,
                data: {},
            };

            // initialize the products with a list of products with amount 0
            this.order.products = [];
            for (const product of this.options.productOptions.products) {
                this.order.products.push({
                    id: product.id,
                    amount: 0,
                });
            }

            // Reset custom fields to nothing
            this.order.customFields = [];
            // Initialize all custom fields with default values.
            for (const field of this.options.preProductOptions.concat(
                this.options.postProductOptions,
            )) {
                let input: customFieldDto;
                if (field.datatype === "boolean") {
                    input = {
                        id: field.id,
                        value:
                            field.default === "true" || field.default === true,
                    };
                } else if (field.datatype === "integer") {
                    input = {
                        id: field.id,
                        value: field.default
                            ? parseInt(field.default.toString())
                            : "",
                    };
                } else {
                    input = {
                        id: field.id,
                        value: field.default,
                    };
                }
                this.order.customFields.push(input);
            }

            this.order.toggleableFields = {};
            // If donations are enabled, the default is 0
            if (this.options.toggleableFields.donation.enabled) {
                this.order.toggleableFields.donation = 0;
            }
        },
    },
    getters: {
        orderTotal(): number {
            let total = 0;
            total += this.order.products.reduce((acc: number, item): number => {
                return (
                    acc +
                    item.amount * (this.productIdToBind[item.id]?.price ?? 0)
                );
            }, 0);

            const parsedDonation = parseFloat(
                this.order.toggleableFields.donation as string,
            );
            if (!isNaN(parsedDonation) && parsedDonation > 0) {
                total += parsedDonation * 100;
            }

            const parsedDiscount = parseFloat(
                this.order.toggleableFields.discount as string,
            );
            if (!isNaN(parsedDiscount) && parsedDiscount > 0) {
                total -= parsedDiscount * 100;
            }

            return total;
        },
        productIdToBind(): Record<productBind["id"], productBind> {
            const map: Record<productBind["id"], productBind> = {};
            for (const bind of this.binds) {
                map[bind.id] = unref(bind);
            }
            return map;
        },
        selectedProducts(): productDto[] {
            return unref(this.order.products).filter(
                (product) => product.amount > 0,
            );
        },
        availableServices(): displayItem[] {
            if (!this.locationValid) {
                return this.availableProducts;
            }

            return this.availableProducts.filter(
                (item) =>
                    this.productIdToBind[item.id]?.timeslots.length ?? 0 > 0,
            );
        },
        availableProducts(): displayItem[] {
            return unref(this.options.productOptions.products).map((item) => {
                const result = unref(item as displayItem);
                result.price = this.productIdToBind[item.id]?.price;
                return result;
            });
        },
        getAvailableDays(): deliveryDay[] {
            const days = klona(this.options.timeslots) as deliveryDay[];

            const mode = this.options.productOptions.mode;

            const selectedProducts = unref(this.selectedProducts);

            // If no product has been selected, then there are no days to choose from
            if (selectedProducts.length == 0) {
                return [];
            }

            // If the location is yet to be chosen, the bind may be undefined!
            if (!this.locationValid) {
                return days;
            }

            if (mode === "SERVICE") {
                const serviceId = selectedProducts[0].id;
                const service = unref(
                    this.options.productOptions.products,
                ).find((v) => v.id === serviceId);

                for (const day of days) {
                    day.slots = day.slots.filter((slot) =>
                        service?.capacities.find((c) => c.timeslot == slot.id),
                    );
                    const serviceTimeslots =
                        this.productIdToBind[serviceId].timeslots;
                    day.slots = day.slots.filter((slot) =>
                        serviceTimeslots.includes(slot.id),
                    );
                }
            } else if (mode === "PRODUCT") {
                const productIds = selectedProducts.map(
                    (product) => product.id,
                );

                // The products variable will hold all the products that are selected in the order.
                const products = unref(
                    this.options.productOptions.products,
                ).filter((item) => productIds.includes(item.id));

                // If we order more of a product than we can deliver in any slot, return an empty array.
                for (const product of products) {
                    // Amount must exist as we are only looking at selected products
                    const amount =
                        selectedProducts.find(
                            (orderProduct) => orderProduct.id === product.id,
                        )?.amount ?? -1;

                    if (amount > product.maxCapacity) {
                        // There will be no slots with enough capacity for this product. So there are no slots available for this order.
                        return [];
                    }

                    const bind = this.productIdToBind[product.id];

                    for (const day of days) {
                        day.slots = day.slots.filter((slot: deliverySlot) => {
                            const capacity = product.capacities.find(
                                (capSlot) => capSlot.timeslot === slot.id,
                            );

                            return (
                                bind.timeslots.includes(slot.id) &&
                                amount <= (capacity?.capacity ?? Infinity)
                            );
                        });
                    }
                }
            }
            return days.filter((day) => day.slots.length > 0);
        },
        getAvailableTimeslots(): deliverySlot[] {
            return this.selectedDay?.slots ?? [];
        },
        getPostOrderDTO(): OrderDTO {
            // Returns the order in a format that is requested by the API in the
            //  body of the POST request.
            const result = klona(this.order) as OrderDTO;

            // Parse the telephone number
            if (result.toggleableFields.telephone) {
                result.toggleableFields.telephone = formatIntlTelephone(
                    result.toggleableFields.telephone,
                );
            }

            // Parse the donation
            if (result.toggleableFields.discount !== undefined) {
                result.toggleableFields.discount =
                    parseFloat(this.order.toggleableFields.discount as string) *
                    100;
            }

            // Parse the discount
            if (result.toggleableFields.donation !== undefined) {
                result.toggleableFields.donation =
                    parseFloat(this.order.toggleableFields.donation as string) *
                    100;
            }

            // Remove empty products in service mode
            if (this.options.productOptions.mode == "SERVICE") {
                result.products = result.products.filter(
                    (product) => product.amount > 0,
                );
            }

            return result;
        },
    },
});
