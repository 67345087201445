<script setup lang="ts">
    import { useOrderCreationStore } from "@/store/orderCreation";
    import useVuelidate from "@vuelidate/core";
    import { decimal, maxValue, minValue } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { onMounted, reactive, ref } from "vue";
    import { MaskInputOptions } from "maska";
    import { vMaska } from "maska/vue";

    const orderStore = useOrderCreationStore();

    const fieldModel = ref<number | string | undefined>();

    onMounted(() => {
        fieldModel.value = orderStore.order.toggleableFields.donation;
    });

    const v$ = useVuelidate(
        {
            fieldModel: {
                mustBePositive: minValue(0),
                decimal: decimal(2),
                maxValue: maxValue(1000),
            },
        },
        {
            fieldModel,
        },
    );

    const donationMask = reactive<MaskInputOptions>({
        onMaska: (value) => {
            if (!isNaN(parseFloat(value.unmasked))) {
                orderStore.order.toggleableFields.donation = parseFloat(
                    value.unmasked,
                );
            }
        },
        number: {
            locale: "nl",
            fraction: 2,
            unsigned: true,
        },
    });
</script>

<template>
    <template v-if="orderStore.options.toggleableFields.donation.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">Donatie</div>
        <v-text-field
            v-model="fieldModel"
            v-maska="donationMask"
            density="compact"
            placeholder="Donatie"
            variant="outlined"
            prepend-inner-icon="mdi-currency-eur"
            :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
            @focusout="
                () => {
                    v$.fieldModel.$validate();
                }
            "
        />
    </template>
</template>

<style scoped></style>
